const user_id = localStorage.getItem('userId')
const API_URL = {
  sign_in: 'signin',
  parliment: 'parliment?page=1&limit=100',
  district: 'district?page=1&limit=100',
  acs: 'acs?page=1&limit=100',
  state: 'state?page=1&limit=100',
  cluster2: 'cluster2',
  cluster1: 'cluster1',
  role: 'role',
  booth: 'booth?state_id=6142be5dbeb0d405a8e6ad61&page=1&limit=600',
  create_user: '/user',
  get_user: 'getone/user/list?id=' + user_id,
  forgotpwd: '/forgotpassword', //forgot password
  forgot_pwd_Verify: '/forgotpassword/verification', //forgot password verify
  update_password: '/update/password',
  change_password: '/changepassword',
  payoutStatus:'/jobs',
  states: '/states',
  get_agentdata: '/get/agentdata',

  update_socalmedia: '/update/socialmedia',
  update_remarks: '/update/remarks',
  update_personal: '/update/personal',
  update_social_work: '/update/social/work',
  update_party_work: '/update/party/work',
  update_admin: '/update/clusterone/admin',
  update_user: '/update/user',
  update_family: '/update/family',
  update_childern: '/update/childern',
  add_children: '/add/childern',

  //Social Organization API's
  list_social_organization: '/socialorg',
  insert_social_organization: '/socialorg',

  //Govt scheme API's
  list_government_scheme: '/govtscheme',
  insert_government_scheme: '/govtscheme',

  //Qualification API's
  list_qualifiaction: '/qualification',
  insert_qualification: '/qualification',

  //Profession API's
  list_profession: '/profession',
  insert_profession: '/profession',

  //Religion API's
  list_religion: '/religion',
  insert_religion: '/religion',

  //Political Party API's
  list_politicalparty: '/politicalparty',
  insert_politicalparty: '/politicalparty',

  //Category API's
  list_category: '/category',
  insert_category: '/category',

  //Caste API's
  list_caste: '/caste',
  insert_caste: '/caste',

  //Income Group API's
  list_incomegroup: '/incomegroup',
  insert_incomegroup: '/incomegroup',

  //Cluster1 API's
  list_cluster1: '/cluster1',
  insert_cluster1: '/cluster1',

  //Cluster2 API's
  list_cluster2: '/cluster2',
  list_cluster2List: '/cluster2List',
  insert_cluster2: '/cluster2',
  cluster2_booth_list: '/clt2booth',

  //Mother Tongue API's
  list_mother_tongue: '/mothertongue',
  insert_mother_tongue: '/mothertongue',

  //Blood Group API's
  list_blood_group: '/bloodgroup',
  insert_blood_group: '/bloodgroup',

  //Colony API's
  list_colony: '/colony',
  search_colony: '/search/colony',
  insert_colony: '/colony',
  update_colony: '/update/colony',
  delete_colony: '/delete/colony',
  find_colony: '/find/colony',

  //Appartment API's
  list_apartment: '/apartment',
  insert_appartment: '/apartment',
  list_colonys: '/colonies',

  //Civic Issue API's
  list_civic_issue: '/civicissue',
  insert_civic_issue: '/civicissue',

  //Relation Type API's
  list_relation_type: '/relationtype',
  insert_relation_Type: '/relationtype',

  //Template API's
  list_template: '/msgtemplate',
  insert_template: '/msgtemplate',
  aclist_template: '/acs',

  //Campaign API's
  list_campaign: '/campaign',
  insert_campaign: '/campaign',
  aclist_campaign: '/acs',
  list_variables: '/templatevariable',

  //State API's
  list_state: '/state',

  //Parliament API's
  list_parliament: '/parliment',

  //Assembly API's
  list_assembly: '/acs',
  insert_assembly: '/acs',

  //booth API's
  list_booths: '/booth',

  //districts API's
  list_district: '/district',
  insert_disrtict: '/district',

  // Reports API's
  report_division: '/reports/division',
  report_area: '/reports/area',
  report_section: '/reports/section',
  report_profession: '/reports/profession',
  report_caste: '/reports/subcaste',
  report_qualification: '/reports/qualification',
  report_political_party: '/reports/politicalParty',
  report_income_group: '/reports/incomeGroup',
  report_religion: '/reports/religion',
  report_blood_group: '/reports/bloodGroup',
  report_mother_tongue: '/reports/motherTongue',
  report_family_house: '/reports/familyHouse',
  report_social_activity: '/reports/socialActivity',
  report_other_state: '/reports/otherState',
  report_govt_schemes: '/reports/govtschemes',
  report_colony_apartment: '/reports/colonyApartment',
  report_digital_letter_views: '/reports/digital_letter_views',
  report_campaign: '/reports/campaign',
  report_voters: '/reports/voters',
  report_part_wise_campaign: '/reports/partwisecampaign',
  report_category: '/reports/category',
  report_civic_issue: '/reports/civicissues',
  report_mobile_number: '/reports/mobileNumber',
  report_residential_status: '/reports/residevoterlist',
  report_non_voter: '/reports/nonVoters',

  //candidate profile API's
  list_candidate_profile: '/candidate',
  insert_candidate_profile: '/candidate',

  //party profile API's
  list_party_profile: '/partyprofile',
  insert_party_profile: '/partyprofile',

  //upload file API
  upload_file: '/imageupload',

  list_admins: '/list/admins',
  list_ac_mandal: '/list/ac/mandal',
  list_ac_village: '/list/ac/village',
  roles: '/roles',
  permissions: '/list/Adminsdata',

  deleteAgent: '/deleteAgent',
  user_values: '/user/values',
  category_caste: '/category/caste',

  //user
  list_user: '/user/list',
  update: '/user',
}
export default API_URL
