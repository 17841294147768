import axios from "axios";
const AxiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    tenant: process.env.REACT_APP_TENANT,
  },
});
AxiosInterceptor.interceptors.request.use((config) => {
  const token = localStorage.jwtToken;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
AxiosInterceptor.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    if (error?.response?.status === 401) {
      console.log("error");
    }
    return error;
  }
);
export default AxiosInterceptor;
