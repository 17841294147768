import React, { Component, Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { CSpinner } from "@coreui/react-pro";
import "./scss/style.scss";
import PageReload from "./services/redux-store/PageReload";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./menu items/auth/Signin"));
const ForgotPassword = React.lazy(() =>
  import("./menu items/auth/forgotPassword")
);
const UpdatePassword = React.lazy(() =>
  import("./menu items/auth/updatePassword")
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <PageReload />
        <Suspense fallback={<CSpinner color="primary" />}>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgotPassword"
              name="Forgot Password"
              render={(props) => <ForgotPassword />}
            />
            <Route
              exact
              path="/updatePassword/:token"
              name="Update Password"
              render={(props) => <UpdatePassword {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
